import { Luminous } from 'luminous-lightbox';

export default class SlickGalleryController {

  constructor () {
    this.setupImageSlider()
    this.setupLuminous()
  }

  setupImageSlider () {
    $('.slick-image-gallery').slick({
      dots: true,
      slidesToShow: 1,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      adaptiveHeight: true
    });
  }

  setupLuminous (){
    $('.slick-image-gallery .item a').each(function(){
      new Luminous(this);
    })
  }
}